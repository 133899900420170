<template>
  <q-select
    v-model="business_area"
    :options="availableBusinessAreas"
    :label="$t('rac.business_area')"
    :error="error"
    :error-message="errorMessage"
    v-bind="$attrs"
  />
</template>

<script>
import { business_areas } from 'api/onwardtravel.js'
export default {
  name: 'MBusinessArea',
  props: {
    value: String,
    slug: String,
    error: Boolean,
    errorMessage: String
  },
  data () {
    return {
      availableBusinessAreas: []
    }
  },
  computed: {
    business_area: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  created () {
    const org = this.$store.getters.authentication.organisation
    if (org && org.business_areas) {
      this.availableBusinessAreas.concat(org.business_areas)
    }

    business_areas(this.slug || (org ? org.slug : null)).then(({ data }) => {
      const areas = data[0] || ['N/A']
      areas.forEach(c => {
        if (!this.availableBusinessAreas.includes(c)) this.availableBusinessAreas.push(c)
      })
    })
  }
}
</script>
